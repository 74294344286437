<template>
  <v-app>
    <v-system-bar class="d-print-none pt-3" height="30" app lights-out>
      <v-toolbar-items class="text-right">
        <v-btn
          large
          rounded
          dark
          href="javascript:window.print()"
          class="title mr-2 rounded"
          color="info"
        >
          <v-icon class="mr-1" color="white" large>mdi-printer</v-icon>พิมพ์
        </v-btn>
      </v-toolbar-items>
    </v-system-bar>

    <div id="exportContent">
      <div class="page">       
          <div class="text-right">
            แบบ สรุป ก.
          </div>
          <div align="center">
        
            <div class="head">
              แบบสรุปผลคะแนนการประเมิน ภาค ก สมรรถนะในการบริหารงานในหน้าที่
            </div>
            <div class="head">
              ในการคัดเลือกบุคคลเพื่อบรรจุและแต่งตั้งให้ดำรงตำแหน่งรองผู้อำนวยการสถานศึกษา
            </div>
            <div class="head">
              สังกัดสำนักงานคณะกรรมการการอาชีวศึกษา
              <u v-if="users.prefecture_difficulty==='0'">ยกเว้นเขต</u>
              <u v-else-if="users.prefecture_difficulty==='1'">ในเขต</u>พัฒนาพิเศษเฉพาะกิจจังหวัดชายแดนภาคใต้
              เฉพาะจังหวัดยะลา
            </div>
            <div class="head">
              นราธิวาส ปัตตานี และ 4 อำเภอ ในจังหวัดสงขลา (อำเภอจะนะ
              อำเภอสะบ้าย้อย อำเภอเทพา และอำเภอนาทวี)
            </div>
            <div class="head">
              {{ users.college_name }} จังหวัด{{ users.province_name }}
            </div>
          </div>
          <br />
          <table class="text_j table" width="100%">
            <thead class="th">
              <tr>
                <th width="5%" class="text-center th" rowspan="2">ที่</th>
                <th width="15%" class="text-center th" rowspan="2">
                  เลขประจำตัว
                </th>

                <th width="35%" class="text-center th" rowspan="2">
                  ชื่อ-นามสกุล
                </th>

                <th width="20%" class="text-center th pa-2">คะแนนที่ได้</th>
                <th width="25%" class="text-center th" rowspan="2">หมายเหตุ</th>
              </tr>
              <tr>
                <td class="text-center th pa-2">รวม (100 คะแนน)</td>
              </tr>
            </thead>
            <tbody           
            class="td"         
            >
              <tr
              v-for="(item, index) in sedirector_apps"
              :key="item.id">
                <td class="text-center th">{{ index + 1 }}</td>
                <td class="text-center th">
                  {{ item.sedirector_app_idno }}
                </td>

                <td class="th pa-3">{{ item.fristnames }}</td>

                <td class="text-center th"></td>

                <td class="text-center th"></td>
              </tr>  
            </tbody> 

                       
          </table>

          <br />
          <br />
          <table class="text_j" width="100%">
            <tr>
              <td class="text-center">
                ลงชื่อ ....................................................
                ประธานกรรมการ
              </td>
              <td class="text-center" width="50%">
                ลงชื่อ ....................................................
                กรรมการ คนที่ 2
              </td>
            </tr>
            <tr>
              <td class="text-center">
                (..................................................................................)
              </td>
              <td class="text-center">
                (..................................................................................)
              </td>
            </tr>
          </table>

          <br />
          <table class="text_j" width="100%">
            <tr>
              <td class="text-center">
                ลงชื่อ ....................................................
                กรรมการ คนที่ 3
              </td>
              <td class="text-center" width="50%">
                ลงชื่อ ....................................................
                กรรมการ คนที่ 4
              </td>
            </tr>
            <tr>
              <td class="text-center">
                (..................................................................................)
              </td>
              <td class="text-center">
                (..................................................................................)
              </td>
            </tr>
          </table>

          <br />
          <table class="text_j" width="100%">
            <tr>
              <td class="text-center">
                ลงชื่อ ....................................................
                กรรมการ คนที่ 5
              </td>
              <td class="text-center" width="50%"></td>
            </tr>
            <tr>
              <td class="text-center">
                (..................................................................................)
              </td>
              <td class="text-center"></td>
            </tr>
          </table>

          <!-- New Page -->
          <p style="page-break-before: always;">&nbsp;</p>
          <!-- 2 -->
          <br>
          
      
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    ApiKey: "HRvec2021",
    loading: false,
    users: [],
    sedirector_apps: []
  }),

  async mounted() {
    await this.sweetAlertLoading();
    await this.userQuery();
    await this.sedirector_appsQuery();  
    Swal.close();
  },

  methods: {

    async userQuery() {
      let result = await this.$http.post("college.php", {
        college_code: this.pid,
        ApiKey: "HRvec2021"
      });
      this.users = result.data;
     
    },
    async sedirector_appsQuery() {
      this.loading = true;
      let result = await this.$http
        .post("sedirector_app.php", {
          ApiKey: this.ApiKey,
          sedirector_app_year: this.years,
          sedirector_app_time: this.times,
          sedirector_app_college: this.pid,
          sedirector_app_pvc_approve: "pass",     
        })
        .finally(() => (this.loading = false));
      this.sedirector_apps = result.data;
      
    },

    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },

    Export2Doc(element, filename) {
      var preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-16'><title>Export HTML To Doc</title></head><body>";
      var postHtml = "</body></html>";
      var html =
        preHtml + document.getElementById(element).innerHTML + postHtml;

      var blob = new Blob(["\ufeff", html], {
        type: "application/msword"
      });

      // Specify link url
      var url =
        "data:application/vnd.ms-word;charset=utf-16," +
        encodeURIComponent(html);

      // Specify file name
      filename = filename ? filename + ".doc" : "document.doc";

      // Create download link element
      var downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = url;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }

      document.body.removeChild(downloadLink);
    },
    exportToExcel(element, filename) {
      var preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:excel' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Excel</title></head><body>";
      var postHtml = "</body></html>";
      var html =
        preHtml + document.getElementById(element).innerHTML + postHtml;

      var blob = new Blob(["\ufeff", html], {
        type: "application/msexcel"
      });

      // Specify link url
      var url =
        "data:application/vnd.ms-excel;charset=utf-8," +
        encodeURIComponent(html);

      // Specify file name
      filename = filename ? filename + ".xls" : "document.xls";

      // Create download link element
      var downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = url;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
      document.body.removeChild(downloadLink);
    }
  },
  computed: {
    times() {
      return this.$route.query.time;
    },
    years() {
      return this.$route.query.year;
    },
    pid() {
      return this.$route.query.pid;
    },

    date_today_cal() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();

      today = yyyy + "-" + mm + "-" + dd;
      return today;
    }
  },
  props: {
    source: String
  }
};
</script>
<style>
body {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16px;
  font-weight: 250;
}

.u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}
u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}

* {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.table,
.th,
.td {
  border-bottom: 0.5px solid black;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-collapse: collapse;
}

.text_j {
  border-collapse: collapse;
  text-align: justify;
  text-justify: inter-word;
  line-height: 1;
}
.text_l {
  border-collapse: collapse;
  text-align: left;
  text-justify: inter-word;
  line-height: 1;
}

tfoot tr:hover {
  background-color: initial;
}

tbody tr:hover {
  background-color: inherit;
}

td,
th {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
}

tfoot td {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-weight: bold;
}
.textAlignVer {
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  transform: rotate(-90deg);
  position: relative;
  white-space: nowrap;
  margin-bottom: 10px;
}

#verticaltext {
  writing-mode: tb-rl;
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  white-space: nowrap;
  display: block;
  overflow: hidden;
  padding: 0;
}

.page {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  width: 21cm;
  min-height: 29.7cm;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-top: 0cm;
  margin-right: auto;
  margin-bottom: 0cm;
  margin-left: auto;
  padding-top: 1.5cm;
  padding-right: 1cm;
  padding-bottom: 1.5cm;
  padding-left: 1.5cm;
}

.head {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
  line-height: 1;
}

.regular12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
}

.regular16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
}

.bold16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
}

.blod12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
  font-weight: bold;
}

.blackRegula10 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 12pt;
}

.blackBold18 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 18pt;
  font-weight: bold;
}

.largerCheckbox {
  width: 30px;
  height: 30px;
  color: blue;
}
.td_line {
  border-bottom: 1pt dotted black;
}
/*.subpage {
              height: 247mm;
            }*/

.BlackBold10 {
  font-weight: bold;
}

#content {
  display: table;
}

#pageFooter {
  display: table-footer-group;
}

#pageFooter:after {
  counter-increment: page;
  content: counter(page);
}

@media print {
  .page {
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    padding-top: 1.5cm;
    padding-right: 1cm;
    padding-bottom: 1.5cm;
    padding-left: 1.5cm;
  }

  .head {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .regular12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .regular16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .bold16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blod12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blackRegula10 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 14pt;
  }

  .blackBold18 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  .noprint {
    display: none;
  }

  .gap-10 {
    width: 100%;
    height: 10px;
  }
  .gap-20 {
    width: 100%;
    height: 20px;
  }
  .gap-30 {
    width: 100%;
    height: 30px;
  }
}
</style>
